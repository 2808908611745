<template>
  <div>
    <v-container fluid>
      <v-row class="forgot-password-form-container">
        <v-col lg="3"></v-col>
        <v-col cols="12" lg="6">
          <ValidationObserver ref="observer" v-slot="{ passes }">
            <form @submit.prevent="passes(reset)">
              <v-card class="pa-3 forgot-password-form" elevation="10">
                <v-card-title class="d-flex justify-center">
                  <h1>{{ $t('msg.change_password') }}</h1>
                </v-card-title>
                <v-card-text>
<!--                  <v-alert v-if="showInvalidDataError" dense type="warning">
                    {{ $t('error.current_password_does_not_match') }}
                  </v-alert>-->
                  <v-alert v-if="showChangePasswordSuccess" dense type="success">
                    {{ $t('msg.change_password_success') }}
                  </v-alert>

                  <!-- Password Fields -->
                  <ValidationProvider
                      name="current_password"
                      :rules="{ required: true, min: 6, max: 15, regex: /^[a-zA-Z0-9!#@-]+$/ }"
                      v-slot="{ failedRules }"
                      vid="password" ref="observerCurrentPassword"
                  >
                    <v-text-field
                        :error-messages="$translatedErrorMessages(failedRules, 'current_password')"
                        :label="$t('msg.current_password')"
                        append-icon="mdi-lock"
                        type="password"
                        outlined
                        class="pa-1"
                        v-model="loginDto.currentPassword"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                      name="new_password"
                      :rules="{ required: true, min: 6, max: 15, regex: /^[a-zA-Z0-9!#@-]+$/ }"
                      v-slot="{ failedRules }"
                      vid="newPassword"
                  >
                    <v-text-field
                        :error-messages="$translatedErrorMessages(failedRules, 'new_password')"
                        :label="$t('msg.new_password')"
                        append-icon="mdi-lock"
                        type="password"
                        outlined
                        class="pa-1"
                        v-model="loginDto.newPassword"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                      name="confirmPassword"
                      rules="required|confirmed:newPassword"
                      v-slot="{ failedRules}"
                  >
                    <v-text-field
                        :error-messages="$translatedErrorMessages(failedRules, 'confirm_new_password')"
                        :label="$t('msg.confirm_new_password')"
                        append-icon="mdi-lock"
                        type="password"
                        outlined
                        autocomplete="new-password"
                        class="pa-1"
                        v-model="loginDto.confirmNewPassword"
                    ></v-text-field>
                  </ValidationProvider>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-row>
                    <v-col class="text-center">
                      <v-btn class="primary mr-5" @click="passes(reset)">
                        {{ $t('msg.change_button') }}
                      </v-btn>
                      <v-btn class="cancel" @click="cancelReset">
                        {{ $t('msg.cancel_change') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </form>
          </ValidationObserver>
        </v-col>
        <v-col lg="3"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, min, max, regex, confirmed } from 'vee-validate/dist/rules';
import { userService } from "@/services";

extend('required', required);
extend('min', min);
extend('max', max);
extend('regex', regex);
extend('confirmed', confirmed);

export default {
  name: "ChangePassword",
  components: {
    ValidationProvider,
    ValidationObserver,

  },
  data() {
    return {
      showInvalidDataError: false,
      showChangePasswordSuccess: false,
      loginDto: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
    };
  },
  methods: {
    cancelReset() {
      this.$refs.observer.reset();
      this.loginDto = {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      };
      this.resetUserDetailsError();
    },
    resetUserDetailsError() {
      this.showInvalidDataError = false;
      this.showChangePasswordSuccess = false;
    },
    reset() {
      this.resetUserDetailsError();
      userService.changePassword(this.loginDto)
          .then(() => {
            this.showChangePasswordSuccess = true
          })
          .catch((error) => {
            console.log(error)
            if (undefined !== error.response && undefined !== error.response.data && error.response.data.length > 0) {
              let e = {}
              error.response.data.forEach(error => {
                if (error.rule !== undefined) {
                  console.log(error.field + '   ' + error.rule)
                  e[error.field] = [{ rule: error.rule }]
                  if (error.rule === 'current_password_does_not_match') {
                    this.showInvalidDataError = true
                    this.$refs.observerCurrentPassword.applyResult({
                      errors: ['error'],
                      failedRules: ['current_password_does_not_match']
                    });
                  }
                }
              })
            } else {
              this.serverError = true
            }
          });
    },
  },
};
</script>

<style scoped>
.forgot-password-form {
  border: 1px solid #64c2ef;
  box-shadow: none !important;
}

.cancel {
  background-color: gray;
}

.forgot-password-form-container {
  margin: 20px 0 50px !important;
}
</style>
